<template>
  <b-card-code title="Créer une nouvelle transaction">
    <b-card-text>
      <span>Veuillez saisir les coordonnées du paiement </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Etudiant Id"
              label-for="Etudiant Id"
            >
              <validation-provider
                #default="{ errors }"
                name="Etudiant Id"
                rules="required"
              >
                <b-form-input
                  v-model="inscriptions[0].student.id"
                  :state="errors.length > 0 ? false : null"
                  disabled
                  placeholder="Etudiant Id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Année Universitaire"
              label-for="Année Universitaire"
            >
              <validation-provider
                #default="{ errors }"
                name="Année Universitaire"
                rules="required"
              >
                <b-form-input
                  v-model="payment.academic_year"
                  :state="errors.length > 0 ? false : null"
                  disabled
                  placeholder="Année Universitaire"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Frais d'inscription"
              label-for="Frais d'inscription"
            >
              <validation-provider
                #default="{ errors }"
                name="Frais d'inscription"
                rules="required"
              >
                <b-form-input
                  v-model="inscriptions[0].parcour.frais_inscrit"
                  :state="errors.length > 0 ? false : null"
                  disabled
                  placeholder="Frais d'inscription"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Motif paiement"
              label-for="Motif paiement"
            >
              <validation-provider
                #default="{ errors }"
                name="Motif paiement"
                rules="required"
              >
                <v-select
                  v-model="payment.reason_payment"
                  :clearable="false"
                  :options="payements"
                  placeholder="Motif paiement"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Montant"
              label-for="Montant"
            >
              <validation-provider
                #default="{ errors }"
                name="Montant"
                rules="required"
              >
                <b-form-input
                  v-model="payment.amount"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Montant"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Mode de paiement"
              label-for="Mode de paiement"
            >
              <validation-provider
                #default="{ errors }"
                name="Mode de paiement"
                rules="required"
              >
                <v-select
                  v-model="payment.payment_method"
                  :clearable="false"
                  :options="methods"
                  placeholder="Mode de paiement"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Payé par"
              label-for="Payé par"
            >
              <validation-provider
                #default="{ errors }"
                name="Payé par"
                rules="required"
              >
                <b-form-input
                  v-model="students.username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Payé par"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label for="example-datepicker">Date de paiement</label>
            <flat-pickr
              v-model="payment.payment_date"
              class="form-control invoice-edit-input mb-1"
            />
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Description"
              label-for="Description"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-input
                  v-model="payment.description"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addPayments"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import storeAuth from '@/store/store'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,

  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,

  },
  data() {
    return {
      password: '',
      payment: {
        academic_year_id: '',
        reason_payment: '',
        payment_method: '',
        payment_date: '',
        payed_by: '',
        description: '',
        amount: '',
      },
      payements: ['Frais inscription', 'Droit scolaire', 'Certification', 'Autre'],
      methods: ['Espèce', 'Chèque', 'Virement', 'Traite'],
      parcours: [],
      inscriptions: [],
      inscription: {},
      students: [],
      student: {},
      required,
      frais_inscrit: '',
    }
  },
  created() {
    this.getInscriptions()
    this.getParcours()
    this.getStudents()
    this.student_id = this.$route.params.id
    this.payment.academic_year = storeAuth.state.currentAcademicYear.academic_year
    this.payment.academic_year_id = storeAuth.state.currentAcademicYear.id
  },
  methods: {
    async getInscriptions() {
      this.load = 'true'
      const response = await axios.get('/api/inscriptions/', {
        params: {
          student: this.$route.params.id,
        },
      })
      this.inscriptions = response.data
      this.load = 'false'
    },
    async getParcours() {
      this.load = 'true'
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
      this.load = 'false'
    },
    async getStudents() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/authentication/users/detail/${id}`)
      this.students = response.data
      this.load = 'false'
    },
    async addPayments() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/payments/create/',
                {
                  amount: this.payment.amount,
                  reason_payment: this.payment.reason_payment,
                  payed_by: this.students.username,
                  student: this.students.id,
                  parcour: this.inscriptions[0].parcour.id,
                  payment_date: this.payment.payment_date,
                  payment_method: this.payment.payment_method,
                  academic_year: this.payment.academic_year_id,

                },
              )
            this.$router.push('/payments')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Paiement ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
